<template>
  <div>
    <!-- #region Form -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3"> {{ $t("tagEditPage.pageTitle") }}</strong>
          </CCardHeader>
          <CCardBody>

            <span 
              v-if="isRole"
              class="caption"
            >
              {{ $t("common.editCaption") }}
            </span>
            <!-- NOTE:onsubmitの記述によってEnterキー操作を無効化 -->
            <CForm class="submit_form" @submit.prevent>

              <!-- #region 入力フィールド -->
              <CRow>
                <CCol class="label"><strong class="required-mark"> {{$t("tagEditPage.typeLabel")}}</strong></CCol>
                <CCol sm="2">
                  <CSelect
                    :disabled="true"
                    :value.sync="selectedType"
                    :options="TAG_TYPE"
                  />
                </CCol>
              </CRow>

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("common.name") }}</strong></CCol>
                <CCol>
                  <CInput
                    :disabled="!isRole"
                    :plaintext="!isRole"
                    v-model="editJa.name"
                    @keydown.enter="onEnterKeyDown($event)"
                    />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.name.$dirty &&
                      !$v.editJa.name.required
                    "
                  >
                    {{ $t("validations.required", { vName: $t("common.name")})}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.name.$dirty &&
                      !$v.editJa.name.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.name"),vMax: $v.editJa.name.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("common.description") }}</strong></CCol>
                <CCol>
                  <CTextarea
                    :disabled="!isRole"
                    :plaintext="!isRole"
                    class="mb-2"
                    v-model="editJa.description"
                    @keydown.enter="onEnterKeyDown($event)"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.editJa.description.$dirty &&
                      !$v.editJa.description.maxLength
                    "
                  >
                    {{ $t("validations.maxLength", { vName: $t("common.description"),vMax: $v.editJa.description.$params.maxLength.max })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #endregion 入力フィールド -->

              <!-- #region 更新ボタン -->
              <CButton
                v-if="isRole"
                class="px-5 mt-4 float-left"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

            </CForm>
          </CCardBody>

          <CCardFooter>
            <CButton color="secondary" class="" @click="onBackClicked()"
              ><i class="icon cil-arrow-left mr-2"></i
              >{{ $t("common.back") }}</CButton
            >
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Form -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
      :car=[]
    />

    <!-- <SuccessModal
      :successModal="deleteSuccessModal"
      @close="deleteSuccessModal = $event"
      :car=[]
    /> -->

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
// import i18n from "@/i18n" 
import Tag from "./List";
import TAG_TYPE from "@/mixins/property";

const ROLE = {
  ADMIN: "yado_operator_admin",
 };

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "TagEdit",
  mixins: [TAG_TYPE],

  computed: {

    /** ロールの判定 */
    isRole() {
      // アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;
      // adminのみtrueそれ以外はfalse
      return roles.includes(ROLE.ADMIN);
    },


    activeStatus() {
      return this.$v.editJa.$invalid ? "inactive" : "active";
    },

    showSuccessModal() {
      return this.successModal || this.deleteSuccessModal;
    }
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      deleteSuccessModal: false,
      //#endregion Flag
      tagId:this.$route.params.tagId,

      selectedType: 1,

      // getType:[],

      //#region Message
      errorMessage: "error",
      //#endregion Message

      //#region request
      // 日本語入力データ
      editJa: {
        language: "ja",
        name: "",
        description: ""
      },
      // editZh:{
      //   language: "zh",
      //   name: "",
      //   description: ""
      // },
      // editEn:{
      //   language: "en",
      //   name: "",
      //   description: ""
      // },

      requests:[],
      //#endregion request

      //#region Response data
      response: [],
      reponseObj:{},
      //#endregion Response data
    };
  },

  validations() {
    return {
      editJa: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {

    //#region Event

    /**日本語入力中のEnterキー操作は無効 */
    onEnterKeyDown (event) {
      if (event.keyCode !== 13) return;
    },

    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    /** 削除ボタン押下 */
    onDeleteSubmit() {
      this.reqDelete();
    },

    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.$v.editJa.$invalid ?
        this.$v.editJa.$touch() :
        this.reqPut();
    },

    //#endregion Event

    //言語変換
    // languageConvert(datas){
    //   datas.forEach(element => {
    //     if(element.language === this.$store.state.currentLanguage){
    //       this.editJa.language = element.language;
    //       this.editJa.name = element.name;
    //       this.editJa.description = element.description;
    //     }
    //   });
    // },

    /**取得データのマスタータイプ取得 */
    // getMasterType(type){
    //   console.log(this.selectItems)
    //   const typeObj = this.selectItems.filter((v) => v.value === type);
    //   console.log(typeObj)

    //   this.masterTypeLabel = typeObj.label;
    // },

    /**取得データセット */ 
    dataInsert(data){
      console.log(data)

       // 日本語データ
       // NOTE:多言語用の画面でないため、日本語データだけ挿入
      if(data.language === `ja`){
        this.editJa.language = data.language;
        this.editJa.name = data.name;
        this.editJa.description = data.description;
        this.editJa.displayable = data.displayable;
      }

      // TODO:多言語対応後に以下のような処理を行う
      // datas.forEach(element => {
      //    // 日本語データ
      //   if(element.language === `ja`){
      //     this.editJa.language = element.language;
      //     this.editJa.name = element.name;
      //     this.editJa.description = element.description;
      //   }

        // //中国語データ
        // if(element.language === `zh`){
        //   this.editZh.language = element.language;
        //   this.editZh.name = element.name;
        //   this.editZh.description = element.description;
        // }

        // //英語データ
        // if(element.language === `en`){
        //   this.editEn.language = element.language;
        //   this.editEn.name = element.name;
        //   this.editEn.description = element.description;
        // }
      // });
    },

    //#region Request

    /** 取得 */
    reqGet() {

      const callback = (a) => {
          this.response = a;
          this.dataInsert(this.response)
          this.loading = false;
      };

      const errorCallback = (err) => {
        console.log(err);
        this.loading = false;
      };

      this.reqGetTag(callback,errorCallback);
    },

    /** 更新 */
    reqPut() {

      this.requests.push(this.editJa)
      const body = this.requests;

      const callback = () => this.successModal = true;

      const errorCallback = (err) => {
        console.log(err);
        this.errorMessage = `${err.name} : ${err.message}`;
        this.errorModal = true;
      }

      this.reqPutTag(body,callback,errorCallback);
    },

    //#endregion Request


    //#region Method

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    backPage() {
      this.$router.push({
        path: `${Tag.pageUrl}/list`,
        query: { type: this.selectedType },
      });
    },
    //#endregion Method
  },

  beforeMount() {
    this.selectedType = Number(this.$route.query.type);
    console.log("beforeMount:"+this.selectedType);
    this.reqGet();
  },
};
</script>